import { Flex, Text } from "@chakra-ui/react";
import StatusBadge from "components/badge/StatusBadge";
import { USER_ROLES } from "constants/dbEnums";

export const requirementsColumn = [
  {
    Header: "User Name",
    accessor: "full_name"
  },
  {
    Header: "Role",
    accessor: "role",
    Cell: ({ value }) => (
      <Flex align="center" justify={'center'}>
        <Text>
          <StatusBadge status={USER_ROLES[value]} />
        </Text>
      </Flex>
    ),
  },
  {
    Header: "Phone",
    accessor: "phone"
  },
  {
    Header: "Email",
    accessor: "email"
  },
  {
    Header: "PROPERTY TYPE",
    accessor: "property_type",
  },
  {
    Header: "AD TYPE",
    accessor: "ad_type",
  },
  {
    Header: "COMMENT",
    accessor: "comment"
  },
  {
    Header: "REQUIREMENT ADDRESS",
    accessor: "address",
    Cell: ({ value }) => (
      <Text
        cursor="pointer"
        whiteSpace={"nowrap"}
        style={{ fontSize: "11px", fontWeight: 600, textAlign: "left" }}
      >
        {value}
      </Text>
    ),
  },
];
