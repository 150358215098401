import { MEALS_AVAILABLE_TAGS } from "constants/dbEnums";
import { INDOOR_AMENITIES_TAGS } from "constants/dbEnums";
import { PROPERTY_MANAGED_BY_TAGS } from "constants/dbEnums";
import { COMMON_AREAS_TAGS } from "constants/dbEnums";
import { MEALS_TAGS } from "constants/dbEnums";
import { INCLUDED_SERVICE_TAGS } from "constants/dbEnums";
import { GATE_TIMING_TAGS } from "constants/dbEnums";
import { LOCK_IN_PERIOD_TAGS } from "constants/dbEnums";
import { MEALS_TYPE_TAGS } from "constants/dbEnums";
import { USER_ROLE } from "constants/dbEnums";
import { ROOM_TYPE_TAGS } from "constants/dbEnums";
import { FURNISH_TYPE_TAGS } from "constants/dbEnums";
import { SECURITY_DEPOSIT_TYPE } from "constants/dbEnums";
import { NOTICE_PERIOD } from "constants/dbEnums";
import { PROFESSION_OPTIONS } from "constants/dbEnums";
import { PG_RULES } from "constants/dbEnums";

const Joi = require("joi");
const professionValues = PROFESSION_OPTIONS?.map((option) => option.id);
const pgRuleIds = PG_RULES.map((rule) => rule.id);
const securityDepositTypes = SECURITY_DEPOSIT_TYPE.map((type) => type.value);

const pgSchema = {
  create: Joi.object({
    owner_id: Joi.string().required(),
    type: Joi.string().required().valid("PG"),
    owner_role: Joi.string().required().valid(...Object.values(USER_ROLE)),
    pg_name: Joi.string().required(),
    preferred_tenant: Joi.string().required().valid("male", "female", "all"),
    suitable_for: Joi.array()
      .items(Joi.string().valid(...professionValues))
      .min(1)
      .required()
      .messages({
        "array.min": '"suitable for" must contain at least one valid option.',
        "any.required": '"suitable for" is required.',
      }),
    total_floors: Joi.number().min(1).max(99).integer(),
    room_sharing_type: Joi.array()
      .items(Joi.string().valid(...Object.values(ROOM_TYPE_TAGS)))
      .min(1)
      .required()
      .messages({
        "array.base": '"room_sharing_type" must be an array.',
        "array.includes": '"room_sharing_type" must contain only valid options.',
        "array.min":
          '"room_sharing_type" must contain at least one valid option.',
        "any.required": '"room_sharing_type" is required.',
      }),
    capacity_and_availability: Joi.object({
      private_room: Joi.object({
        rooms: Joi.number().integer().min(0).max(99),
        attached_bathroom: Joi.boolean(),
        attached_balcony: Joi.boolean(),
      }),
      twin_sharing: Joi.object({
        rooms: Joi.number().integer().min(0).max(99),
        attached_bathroom: Joi.boolean(),
        attached_balcony: Joi.boolean(),
      }),
      triple_sharing: Joi.object({
        rooms: Joi.number().integer().min(0).max(99),
        attached_bathroom: Joi.boolean(),
        attached_balcony: Joi.boolean(),
      }),
      quad_sharing: Joi.object({
        rooms: Joi.number().integer().min(0).max(99),
        attached_bathroom: Joi.boolean(),
        attached_balcony: Joi.boolean(),
      }),
    }),
    furnish_type: Joi.string()
      .valid(...Object.values(FURNISH_TYPE_TAGS))
      .required(),
    furnish_type_items: Joi.when("furnish_type", {
      is: "fully_furnished",
      then: Joi.object()
        .pattern(
          Joi.string().valid(...Object.values(INDOOR_AMENITIES_TAGS)),
          Joi.number().min(1)
        )
        .min(5)
        .required(),
      otherwise: Joi.when("furnish_type", {
        is: "semi_furnished",
        then: Joi.object()
          .pattern(
            Joi.string().valid(...Object.values(INDOOR_AMENITIES_TAGS)),
            Joi.number().min(1)
          )
          .min(3)
          .required(),
        otherwise: Joi.forbidden(),
      }),
    }),
    covered_parking: Joi.number().integer().max(10),
    open_parking: Joi.number().max(10).integer(),
    property_managed_by: Joi.string().valid(
      ...Object.values(PROPERTY_MANAGED_BY_TAGS),
      ""
    ),
    manager_stays_at_pg: Joi.string().valid(
      ...Object.values(MEALS_AVAILABLE_TAGS),
      ""
    ),
    pricing: Joi.object({
      private_room: Joi.when('room_sharing_type', {
        is: Joi.array().items('private_room'),
        then: Joi.object({
          price: Joi.number().min(1000).max(1000000).required(),
          security_deposit: Joi.object({
            type: Joi.string().valid(...securityDepositTypes),
            amount: Joi.when('type', {
              is: ['fixed', 'multiple_of_rents'],
              then: Joi.number().min(100).required(),
              otherwise: Joi.number().valid(0),
            }),
            month: Joi.when('type', {
              is: ['multiple_of_rents'],
              then: Joi.number().required(),
              otherwise: Joi.number().valid(''),
            })
          }),
        }),
        otherwise: Joi.forbidden(),
      }),
  
      twin_sharing: Joi.when('room_sharing_type', {
        is: Joi.array().items('twin_sharing'),
        then: Joi.object({
          price: Joi.number().min(1000).max(1000000).required(),
          security_deposit: Joi.object({
            type: Joi.string().valid(...securityDepositTypes).required(),
            amount: Joi.when('type', {
              is: ['fixed', 'multiple_of_rents'],
              then: Joi.number().min(100).required(),
              otherwise: Joi.number().valid(0),
            }),
            month: Joi.when('type', {
              is: 'multiple_of_rents',
              then: Joi.number().required(),
              otherwise: Joi.number().valid(''),
            }),
          }),
        }),
        otherwise: Joi.forbidden(),
      }),
  
      triple_sharing: Joi.when('room_sharing_type', {
        is: Joi.array().items('triple_sharing'),
        then: Joi.object({
          price: Joi.number().min(1000).max(1000000).required(),
          security_deposit: Joi.object({
            type: Joi.string().valid(...securityDepositTypes).required(),
            amount: Joi.when('type', {
              is: ['fixed', 'multiple_of_rents'],
              then: Joi.number().min(100).required(),
              otherwise: Joi.number().valid(0),
            }),
            month: Joi.when('type', {
              is: 'multiple_of_rents',
              then: Joi.number().required(),
              otherwise: Joi.number().valid(''),
            }),
          }),
        }),
        otherwise: Joi.forbidden(),
      }),
  
      quad_sharing: Joi.when('room_sharing_type', {
        is: Joi.array().items('quad_sharing'),
        then: Joi.object({
          price: Joi.number().min(1000).max(1000000).required(),
          security_deposit: Joi.object({
            type: Joi.string().valid(...securityDepositTypes).required(),
            amount: Joi.when('type', {
              is: ['fixed', 'multiple_of_rents'],
              then: Joi.number().min(100).required(),
              otherwise: Joi.number().valid(0),
            }),
            month: Joi.when('type', {
              is: 'multiple_of_rents',
              then: Joi.number().required(),
              otherwise: Joi.number().valid(''),
            }),
          }),
        }),
        otherwise: Joi.forbidden(),
      }),
    }).optional().allow(null),
    maintenance_charges: Joi.number().integer(),
    booking_amount: Joi.number(),
    other_charges: Joi.number(),
    negotiable: Joi.boolean(),
    is_electricity_and_water_charge: Joi.boolean(),
    included_services: Joi.array().items(
      Joi.string().valid(...Object.values(INCLUDED_SERVICE_TAGS))
    ),
    meals_available: Joi.string()
      .valid(...Object.values(MEALS_AVAILABLE_TAGS))
      .optional(),
    meal_type: Joi.string().valid(...Object.values(MEALS_TYPE_TAGS), ""),
    meal_amount: Joi.when("meals_available", {
      is: Joi.valid(MEALS_AVAILABLE_TAGS.NEED_TO_PAY_EXTRA),
      then: Joi.number().min(0).max(100000).required(),
      otherwise: Joi.forbidden(),
    }),
    meal_on_weekends: Joi.array().items(
      Joi.string().valid(...Object.values(MEALS_TAGS))
    ),
    meal_on_weekdays: Joi.array().items(
      Joi.string().valid(...Object.values(MEALS_TAGS))
    ),
    notice_period: Joi.number()
      .integer()
      .valid(...Object.values(NOTICE_PERIOD), 0),
    lock_in_period: Joi.object({
      type: Joi.string().valid(...Object.values(LOCK_IN_PERIOD_TAGS), ""),
      period: Joi.when("type", {
        is: "custom",
        then: Joi.number().integer().max(30).required(),
        otherwise: Joi.number().valid(0),
      }),
    }),
    location: Joi.object({
      type: Joi.string(),
      label: Joi.string(),
      place_type: Joi.string().allow(""),
      coordinates: Joi.array().items(Joi.number()).required(),
      city: Joi.string().required().trim(),
      state: Joi.string(),
      state_code: Joi.string().allow(""),
      country_code: Joi.string().allow(""),
      country: Joi.string(),
      locality: Joi.string().required(),
      sub_locality: Joi.string().allow(""),
      street: Joi.string().allow("").trim(),
      building: Joi.string().allow(""),
      postal_code: Joi.string().allow(""),
    }).required()
    .custom((value, helpers) => {
      if (!value.locality || value.locality.trim() === "") {
        return helpers.error("any.custom", { key: "locality" });
      }
      return value;
    }),
    amenities: Joi.array().items(Joi.string()).required(),
    pg_rules: Joi.array().items(Joi.string().valid(...pgRuleIds)),
    common_areas: Joi.array().items(
      Joi.string().valid(...Object.values(COMMON_AREAS_TAGS))
    ),
    photos: Joi.array().items(Joi.string().trim()),
    description: Joi.string().required().trim(),
    gate_timing: Joi.string().valid(...Object.values(GATE_TIMING_TAGS), ""),
    available_from: Joi.date().min(new Date().setUTCHours(0, 0, 0, 0)).required(),
    hidden: Joi.boolean().default(false),
    verified: Joi.boolean().default(false),
    attached_bathroom: Joi.boolean(),
    attached_balcony: Joi.boolean(),
  }).unknown(),
  update: Joi.object({
    owner_id: Joi.string().required(),
    type: Joi.string().required().valid("PG"),
    owner_role: Joi.string().required().valid(...Object.values(USER_ROLE)),
    pg_name: Joi.string().required(),
    preferred_tenant: Joi.string().required().valid("male", "female", "all"),
    suitable_for: Joi.array()
      .items(Joi.string().valid(...professionValues))
      .min(1)
      .required()
      .messages({
        "array.min": '"suitable for" must contain at least one valid option.',
        "any.required": '"suitable for" is required.',
      }),
    total_floors: Joi.number().min(1).max(99).integer(),
    room_sharing_type: Joi.array()
      .items(Joi.string().valid(...Object.values(ROOM_TYPE_TAGS)))
      .min(1)
      .required()
      .messages({
        "array.base": '"room_sharing_type" must be an array.',
        "array.includes": '"room_sharing_type" must contain only valid options.',
        "array.min":
          '"room_sharing_type" must contain at least one valid option.',
        "any.required": '"room_sharing_type" is required.',
      }),
    capacity_and_availability: Joi.object({
      private_room: Joi.object({
        rooms: Joi.number().integer().min(0).max(99),
        attached_bathroom: Joi.boolean(),
        attached_balcony: Joi.boolean(),
      }),
      twin_sharing: Joi.object({
        rooms: Joi.number().integer().min(0).max(99),
        attached_bathroom: Joi.boolean(),
        attached_balcony: Joi.boolean(),
      }),
      triple_sharing: Joi.object({
        rooms: Joi.number().integer().min(0).max(99),
        attached_bathroom: Joi.boolean(),
        attached_balcony: Joi.boolean(),
      }),
      quad_sharing: Joi.object({
        rooms: Joi.number().integer().min(0).max(99),
        attached_bathroom: Joi.boolean(),
        attached_balcony: Joi.boolean(),
      }),
    }),
    furnish_type: Joi.string()
      .valid(...Object.values(FURNISH_TYPE_TAGS))
      .required(),
    furnish_type_items: Joi.when("furnish_type", {
      is: "fully_furnished",
      then: Joi.object()
        .pattern(
          Joi.string().valid(...Object.values(INDOOR_AMENITIES_TAGS)),
          Joi.number().min(1)
        )
        .min(5)
        .required(),
      otherwise: Joi.when("furnish_type", {
        is: "semi_furnished",
        then: Joi.object()
          .pattern(
            Joi.string().valid(...Object.values(INDOOR_AMENITIES_TAGS)),
            Joi.number().min(1)
          )
          .min(3)
          .required(),
        otherwise: Joi.forbidden(),
      }),
    }),
    covered_parking: Joi.number().integer().max(10),
    open_parking: Joi.number().max(10).integer(),
    property_managed_by: Joi.string().valid(
      ...Object.values(PROPERTY_MANAGED_BY_TAGS),
      ""
    ),
    manager_stays_at_pg: Joi.string().valid(
      ...Object.values(MEALS_AVAILABLE_TAGS),
      ""
    ),
    pricing: Joi.object({
      private_room: Joi.when('room_sharing_type', {
        is: Joi.array().items('private_room'),
        then: Joi.object({
          price: Joi.number().min(1000).max(1000000).required(),
          security_deposit: Joi.object({
            type: Joi.string().valid(...securityDepositTypes, ''),
            amount: Joi.when('type', {
              is: ['fixed', 'multiple_of_rents'],
              then: Joi.number().min(100).required(),
              otherwise: Joi.number().valid(0),
            }),
            month: Joi.when('type', {
              is: ['multiple_of_rents'],
              then: Joi.number().required(),
              otherwise: Joi.number().valid(''),
            })
          }),
        }),
        otherwise: Joi.forbidden(),
      }),
  
      twin_sharing: Joi.when('room_sharing_type', {
        is: Joi.array().items('twin_sharing'),
        then: Joi.object({
          price: Joi.number().min(1000).max(1000000).required(),
          security_deposit: Joi.object({
            type: Joi.string().valid(...securityDepositTypes).required(),
            amount: Joi.when('type', {
              is: ['fixed', 'multiple_of_rents'],
              then: Joi.number().min(100).required(),
              otherwise: Joi.number().valid(0),
            }),
            month: Joi.when('type', {
              is: 'multiple_of_rents',
              then: Joi.number().required(),
              otherwise: Joi.number().valid(''),
            }),
          }),
        }),
        otherwise: Joi.forbidden(),
      }),
  
      triple_sharing: Joi.when('room_sharing_type', {
        is: Joi.array().items('triple_sharing'),
        then: Joi.object({
          price: Joi.number().min(1000).max(1000000).required(),
          security_deposit: Joi.object({
            type: Joi.string().valid(...securityDepositTypes).required(),
            amount: Joi.when('type', {
              is: ['fixed', 'multiple_of_rents'],
              then: Joi.number().min(100).required(),
              otherwise: Joi.number().valid(0),
            }),
            month: Joi.when('type', {
              is: 'multiple_of_rents',
              then: Joi.number().required(),
              otherwise: Joi.number().valid(''),
            }),
          }),
        }),
        otherwise: Joi.forbidden(),
      }),
  
      quad_sharing: Joi.when('room_sharing_type', {
        is: Joi.array().items('quad_sharing'),
        then: Joi.object({
          price: Joi.number().min(1000).max(1000000).required(),
          security_deposit: Joi.object({
            type: Joi.string().valid(...securityDepositTypes).required(),
            amount: Joi.when('type', {
              is: ['fixed', 'multiple_of_rents'],
              then: Joi.number().min(100).required(),
              otherwise: Joi.number().valid(0),
            }),
            month: Joi.when('type', {
              is: 'multiple_of_rents',
              then: Joi.number().required(),
              otherwise: Joi.number().valid(''),
            }),
          }),
        }),
        otherwise: Joi.forbidden(),
      }),
    }).optional().allow(null),
    maintenance_charges: Joi.number().integer(),
    booking_amount: Joi.number(),
    other_charges: Joi.number(),
    negotiable: Joi.boolean(),
    is_electricity_and_water_charge: Joi.boolean(),
    included_services: Joi.array().items(
      Joi.string().valid(...Object.values(INCLUDED_SERVICE_TAGS))
    ),
    meals_available: Joi.string()
      .valid(...Object.values(MEALS_AVAILABLE_TAGS))
      .optional(),
    meal_type: Joi.string().valid(...Object.values(MEALS_TYPE_TAGS), ""),
    meal_amount: Joi.when("meals_available", {
      is: Joi.valid(MEALS_AVAILABLE_TAGS.NEED_TO_PAY_EXTRA),
      then: Joi.number().min(0).max(100000).required(),
      otherwise: Joi.forbidden(),
    }),
    meal_on_weekends: Joi.array().items(
      Joi.string().valid(...Object.values(MEALS_TAGS))
    ),
    meal_on_weekdays: Joi.array().items(
      Joi.string().valid(...Object.values(MEALS_TAGS))
    ),
    notice_period: Joi.number()
      .integer()
      .valid(...Object.values(NOTICE_PERIOD), 0),
    lock_in_period: Joi.object({
      type: Joi.string().valid(...Object.values(LOCK_IN_PERIOD_TAGS), ""),
      period: Joi.when("type", {
        is: "custom",
        then: Joi.number().integer().max(30).required(),
        otherwise: Joi.number().valid(0),
      }),
    }),
    location: Joi.object({
      type: Joi.string(),
      label: Joi.string(),
      place_type: Joi.string().allow(""),
      coordinates: Joi.array().items(Joi.number()).required(),
      city: Joi.string().required().trim(),
      state: Joi.string(),
      state_code: Joi.string().allow(""),
      country_code: Joi.string().allow(""),
      country: Joi.string(),
      locality: Joi.string().required(),
      sub_locality: Joi.string().allow(""),
      street: Joi.string().allow("").trim(),
      building: Joi.string().allow(""),
      postal_code: Joi.string().allow(""),
    }).required()
    .custom((value, helpers) => {
      if (!value.locality || value.locality.trim() === "") {
        return helpers.error("any.custom", { key: "locality" });
      }
      return value;
    }),
    amenities: Joi.array().items(Joi.string()).required(),
    pg_rules: Joi.array().items(Joi.string().valid(...pgRuleIds)),
    common_areas: Joi.array().items(
      Joi.string().valid(...Object.values(COMMON_AREAS_TAGS))
    ),
    photos: Joi.array().items(Joi.string().trim()),
    description: Joi.string().required().trim(),
    gate_timing: Joi.string().valid(...Object.values(GATE_TIMING_TAGS), ""),
    available_from: Joi.date().required(),
    hidden: Joi.boolean().default(false),
    verified: Joi.boolean().default(false),
    attached_bathroom: Joi.boolean(),
    attached_balcony: Joi.boolean(),
  }).unknown()
}

export default pgSchema;
