import { ZONE_TYPE } from "constants/dbEnums";
import { MONTHS } from "constants/dbEnums";
import { NO_OF_OPEN_SIDES } from "constants/dbEnums";
import { AREA_UNIT_TAGS } from "constants/dbEnums";
import { FACING_TAGS } from "constants/dbEnums";
import { PG_AMENITIES } from "constants/dbEnums";
import { USER_ROLE } from "constants/dbEnums";
import { PROPERTY_COLLECTIONS } from "constants/dbEnums";
import { DISCOUNT_TYPE } from "constants/dbEnums";
import { DIMENSION_UNIT } from "constants/dbEnums";
import { PLOT_PROPERTY_APPROVED_BY } from "constants/dbEnums";
import { CONSTRUCTION_TYPE_OF_PLOT } from "constants/dbEnums";
import { POSSESSION_PERIOD_TAGS } from "constants/dbEnums";
import { OWNERSHIP } from "constants/dbEnums";
import { PLOT_PROPERTY_TYPE } from "constants/dbEnums";

const Joi = require("joi");

const plotValidation = Joi.object({
  owner_id: Joi.string().required(),
  type: Joi.string().required().valid(...Object.values(PROPERTY_COLLECTIONS)),
  owner_role: Joi.string().required().valid(...Object.values(USER_ROLE)),
  property_type: Joi.string()
    .valid(...Object.values(PLOT_PROPERTY_TYPE))
    .required(),
  ownership: Joi.string()
    .valid(...Object.values(OWNERSHIP))
    .required(),
  zone_type: Joi.when("property_type", {
    is: "commercial",
    then: Joi.string().valid(...Object.values(ZONE_TYPE), ""),
    otherwise: Joi.forbidden(),
  }),
  possession_period: Joi.object({
    period: Joi.string()
      .valid(...Object.values(POSSESSION_PERIOD_TAGS))
      .required(),
    month: Joi.when("period", {
      is: Joi.string().valid(
        ...Object.values(POSSESSION_PERIOD_TAGS).filter((key) =>
          key.startsWith("by_")
        )
      ),
      then: Joi.string()
        .valid(...Object.values(MONTHS))
        .required(),
      otherwise: Joi.string().valid(""),
    }).required(),
  }),
  any_construction_done: Joi.boolean().required(),
  type_of_construction: Joi.when("any_construction_done", {
    is: true,
    then: Joi.string()
      .valid(...Object.values(CONSTRUCTION_TYPE_OF_PLOT), "")
      .required(),
    otherwise: Joi.forbidden(),
  }),
  open_sides: Joi.string()
    .valid(...Object.values(NO_OF_OPEN_SIDES))
    .required(),
  property_approved_by: Joi.string().valid(
    ...Object.values(PLOT_PROPERTY_APPROVED_BY),
    ""
  ),
  allow_brokers: Joi.boolean().default(true),
  tax_and_govt_charges_excluded: Joi.boolean(),
  annual_dues_payable: Joi.number(),
  booking_amount: Joi.number(),
  other_charges: Joi.number(),
  price_per_sq_unit: Joi.number(),
  possession_date: Joi.when("possession_status", {
    is: "in_future",
    then: Joi.date().required(),
    otherwise: Joi.date(),
  }),
  boundary_wall: Joi.boolean().required(),
  corner_plot: Joi.boolean().required(),
  area: Joi.object({
    size: Joi.number().positive().min(1).max(9999999).required(),
    unit: Joi.string()
      .valid(...Object.values(AREA_UNIT_TAGS))
      .required(),
  }).required(),
  dimension: Joi.object({
    length: Joi.number().min(0),
    width: Joi.number().min(0),
    unit: Joi.string().valid(...Object.values(DIMENSION_UNIT), ""),
  }),
  width_of_facing_road: Joi.object({
    length: Joi.number().min(0).required(),
    unit: Joi.string().valid(...Object.values(DIMENSION_UNIT), "").required(),
  }).required(),
  facing: Joi.string()
    .valid(...Object.values(FACING_TAGS))
    .required(),
  is_hot_deal: Joi.boolean(),
  discount: Joi.when("is_hot_deal", {
    is: Joi.valid(true),
    then: Joi.object({
      type: Joi.string().valid(...Object.values(DISCOUNT_TYPE)),
      amount: Joi.when("type", {
        is: Joi.valid(DISCOUNT_TYPE.PERCENTAGE),
        then: Joi.number().min(1).max(90),
        otherwise: Joi.number().min(1000).max(90000000),
      }),
    }).required(),
    otherwise: Joi.forbidden(),
  }),
  discount_validity: Joi.when("is_hot_deal", {
    is: Joi.valid(true),
    then: Joi.date().required(),
    otherwise: Joi.forbidden(),
  }),
  financing_available: Joi.when("is_hot_deal", {
    is: Joi.valid(true),
    then: Joi.boolean().required(),
    otherwise: Joi.forbidden(),
  }),
  description: Joi.string().required().trim(),
  approx_price: Joi.number().positive().min(1).max(1000000000).required(),
  negotiable: Joi.boolean(),
  amenities: Joi.array().items(
    Joi.string().valid(...Object.values(PG_AMENITIES), "")
  ),
  photos: Joi.array().items(Joi.string().trim()),
  location: Joi.object({
    type: Joi.string(),
    label: Joi.string(),
    place_type: Joi.string().allow(""),
    coordinates: Joi.array().items(Joi.number()).required(),
    city: Joi.string().required().trim(),
    state: Joi.string(),
    state_code: Joi.string().allow(""),
    country_code: Joi.string().allow(""),
    country: Joi.string(),
    locality: Joi.string().required(),
    sub_locality: Joi.string().allow(""),
    street: Joi.string().allow("").trim(),
    building: Joi.string().allow(""),
    postal_code: Joi.string().allow(""),
  }).required()
  .custom((value, helpers) => {
    if (!value.locality || value.locality.trim() === "") {
      return helpers.error("any.custom", { key: "locality" });
    }
    return value;
  }),
}).unknown();

export default plotValidation;

export const plotKeysArray = ["ownership", "zone_type", "open_sides", "facing", "any_construction_done", "type_of_construction", "boundary_wall", "corner_plot"];

export const plotkeys = {
  ownership: "ownership_and_zone_type",
  zone_type: "ownership_and_zone_type",
  open_sides: "open_sides_and_facing",
  facing: "open_sides_and_facing",
  any_construction_done: "any_construction_done_and_type_of_construction",
  type_of_construction: "any_construction_done_and_type_of_construction",
  boundary_wall: "boundary_wall_and_corner_plot",
  corner_plot: "boundary_wall_and_corner_plot"
};