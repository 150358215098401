import { showToast } from "components/toast/Toast";
import { USER_REQUIREMENTS_API } from "constants/endpoints";

export const submitRequirement = async ({ apiCall, payload }) => {
  try {
    const method = payload._id ? "patch" : "post";
    const url = USER_REQUIREMENTS_API;
    const response = await apiCall[method](url, payload);
    if (response) {
      showToast({
        message: payload?._id
          ? "Requirement updated successfully."
          : "Requirement has been successfully submitted",
        success: true,
        customStyle: {
          background: "#52c41a",
          color: "#fff",
          padding: "10px",
        },
      });
    }
    return response;
  } catch (error) {
    console.error("Error creating/updating requirement:", error);
    showToast({
      message: error?.response?.data?.error?.message
        ? error?.response?.data?.error?.message
        : error?.response?.data?.error,
      success: false,
      customStyle: {
        background: "#F24052",
        color: "#fff",
        padding: "10px",
      },
    });
    throw error?.response?.data?.error?.message
      ? error?.response?.data?.error?.message
      : error?.response?.data?.error;
  }
};

export const displayRequirement = (req) => {
  const text = `${
    req?.property_type ? `Looking for a ${req?.property_type}` : ""
  } ${
    req?.location ? `${req?.location.locality ? `at ${req?.location.locality}, ${req.location.city}` : ''}` : ""
  } ${req.ad_type ? `for ${req.ad_type} ` : ""} ${req.comment ? `at ${req.comment}` : ''}`;
  return text;
};

export const filterUnusedData = (data) => {
  const locationKey = data.address ? 'address' : data.location ? 'location' : null;
  if(locationKey && data[locationKey]){
    const filteredLocation = Object.fromEntries(
      Object.entries(data[locationKey]).filter(([key, value]) => value !== "")
    );
    data[locationKey] = filteredLocation;
  }
  return data;
};
