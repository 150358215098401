import { Text } from "@chakra-ui/react";

export const brokerGroupsColumn = [
  {
    Header: "GROUP NAME",
    accessor: "name"
  },
  {
    Header: "PROPERTY TYPE",
    accessor: "property_type",
  },
  {
    Header: "AD TYPE",
    accessor: "ad_type",
  },
  {
    Header: "COMMENT",
    accessor: "comment"
  },
  {
    Header: "GROUP ADDRESS",
    accessor: "address",
    Cell: ({ value }) => (
      <Text
        cursor="pointer"
        whiteSpace={"nowrap"}
        style={{ fontSize: "11px", fontWeight: 600, textAlign: "left" }}
      >
        {value}
      </Text>
    ),
  },
];
