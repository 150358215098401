import React, { useState, useEffect, useCallback, useRef } from 'react';
import Autosuggest from 'react-autosuggest';
import { Box, FormControl, FormErrorMessage, FormLabel, List, Text, IconButton, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Input, Flex } from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import { GET_AUTO_COMPLETE, GET_LOCATION_DETAILS } from 'constants/endpoints';
import useApi from 'hooks/useApi';
import { showToast } from 'components/toast/Toast';
import { LOCATION_TYPE } from 'constants/dbEnums';
import { getDataFromLocalStorage } from 'utils/common';

const defaultAddress = {
  type: 'Point',
  place_type: '',
  label: '',
  coordinates: [],
  city: '',
  state_code: '',
  state: '',
  country_code: '',
  country: '',
  street: '',
  locality: '',
  sub_locality: '',
  building: '',
  postal_code: ''
};

const AutoSuggestComponent = ({
  name,
  onChange,
  label,
  placeholder,
  isRequired,
  selectedValue = '',
  selectedCity,
  error,
  location,
  result_type = LOCATION_TYPE.LOCALITY
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState(selectedValue);
  const [cities, setCities] = useState([]);
  const { apiCall } = useApi();
  const debounceTimeout = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedLocation, setEditedLocation] = useState(defaultAddress);
  const topCities = getDataFromLocalStorage('topCities');

  useEffect(()=>{
    setValue(selectedValue)
  },[selectedValue]);

  const fetchData = useCallback(() => {
    const citiesData = topCities;
    const res = citiesData?.length > 0 && citiesData.map((val) => ({ id: val.id, name: val.name }));
    setCities(res);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (!location?.locality) {
      setValue("");
    }
  }, [location]);

  useEffect(() => {
    if (!value) {
      const data = JSON.stringify({ city: location?.city });
      onChange({
        target: {
          name: "locality",
          value: data,
        },
      });
    }
  }, [value]);

  async function fetchAutoComplete(query) {
    if (!query || query?.trim().length === 0) return [];

    try {
      const url = `${GET_AUTO_COMPLETE}?q=${query}&city=${selectedCity}&result_type=${result_type}`;
      const response = await apiCall.get(url);
      return response?.data || [];
    } catch (error) {
      console.error('Fetch error:', error);
      return [];
    }
  }

  const onSuggestionsFetchRequested = ({ value }) => {
    const safeValue = value || '';

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(async () => {
      if (safeValue?.trim().length === 0) {
        setSuggestions(cities);
      } else {
        const fetchedSuggestions = await fetchAutoComplete(safeValue);
        setSuggestions(fetchedSuggestions);
      }
    }, 1000);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
  };

  const onSuggestionSelected = async(event, { suggestion }) => {
    const suggestionName = event.target.textContent || '';
    setValue(suggestionName);
      const locationDetailsData = await fetchLocationDetails(suggestion.id);
      const data = JSON.stringify(locationDetailsData);
      onChange({
        target: {
          name,
          value: data,
        },
      });
  };

  async function fetchLocationDetails(placeId) {
    try {
      const url = `${GET_LOCATION_DETAILS}?id=${placeId}`;
      const response = await apiCall.get(url);
      return response?.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  const getSuggestionValue = (suggestion) => suggestion.name || '';

  const renderSuggestion = (suggestion) => {
    return (
    <Box>
      {suggestion.title}
    </Box>
    )
  };

  const renderSuggestionsContainer = ({ containerProps, children }) => {
    return (
      children ? (
        <>
          <Box
            {...containerProps}
            borderWidth="1px"
            borderColor="gray.200"
            borderRadius="md"
            mt={1}
            zIndex={1}
            bg="white"
            boxShadow="md"
            maxH="200px"
            overflowY="auto"
          >
            <List spacing={1}>
              {children}
            </List>
          </Box>
        </>
      ) : null
    );
  };    

  const inputProps = {
    placeholder,
    value,
    onChange: (event, { newValue }) => {
      const safeValue = newValue || '';
      setValue(safeValue);
    }
  };

  const openEditModal = () => {
    setIsEditing(true);
    setEditedLocation({
      ...defaultAddress,
      ...location
    });
  };

  const closeEditModal = () => {
    setIsEditing(false);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedLocation({
      ...editedLocation,
      [name]: value,
    });
  };

  const saveEditedLocation = () => {
    try {
      const filteredLocation = Object.fromEntries(
        Object.entries(editedLocation).filter(([key, value]) => value !== "")
      );
      onChange({
        target: {
          name,
          value: JSON.stringify(filteredLocation),
        },
      });
      setIsEditing(false);
      showToast({
        message: "Locality updated successfully.",
        success: true,
        customStyle: {
          background: "#52c41a",
          color: "#fff",
          padding: "10px",
        },
      });
  
    } catch (error) {
      console.error("Error updating locality", error);
      showToast({
        message: "Failed to update locality. Please try again.",
        success: false,
        customStyle: {
          background: "#ff4d4f",
          color: "#fff",
          padding: "10px",
        },
      });
    }
  };

  const formatLabel = (field) => {
    return field
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };
  
  const formatPlaceholder = (field) => `Enter ${formatLabel(field)}`;
  const requiredFields = ["city", "coordinates"];
  return (
    <FormControl isInvalid={!!error}>
      <FormLabel htmlFor={name}>
        {label}
        {isRequired && (
          <Box as="span" color="red.500" ml={1}>
            *
          </Box>
        )}
        <IconButton
          aria-label="Edit location"
          icon={<EditIcon />}
          onClick={openEditModal}
          variant="ghost"
          ml={2}
          height="20px"
        />
      </FormLabel>
      <Box
        borderWidth="1px"
        borderColor="gray.200"
        borderRadius="md"
        padding={2}
        mt={2}
        _hover={{ borderColor: "gray.400" }}
        _focusWithin={{ borderColor: "blue.500" }}
        width="100%"
      >
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          renderSuggestionsContainer={renderSuggestionsContainer}
          inputProps={{
            ...inputProps,
            style: { width: "100%" }
          }}
        />
      </Box>
      <FormErrorMessage>{error}</FormErrorMessage>
      <Modal isOpen={isEditing} onClose={closeEditModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Location</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" gap={4}>
              {Object.keys(editedLocation).map((field) => (
                <Box key={field}>
                  <FormLabel htmlFor={field}> {formatLabel(field)}
                    {requiredFields.includes(field) && (
                    <Text as="span" color="red.500" fontWeight="bold">*</Text>
                     )}
                  </FormLabel>
                  <Input
                    id={field}
                    name={field}
                    value={editedLocation[field] || ""}
                    placeholder={formatPlaceholder(field)}
                    onChange={handleEditChange}
                  />
                </Box>
              ))}
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={saveEditedLocation}>
              Save
            </Button>
            <Button variant="ghost" onClick={closeEditModal}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FormControl>
  );
};

export default AutoSuggestComponent;