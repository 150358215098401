import React, { useState, useEffect } from "react";
import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useToast
} from "@chakra-ui/react";
import axios from "axios";
import useApi from "hooks/useApi";
import UserRequirementForm from "./UserRequirementForm";
import UserRequirementList from "./UserRequirementList";
import { submitRequirement } from "../../services/userRequirementsService";
import { showToast } from "components/toast/Toast";
import { USER_REQUIREMENTS_API } from "constants/endpoints";
import { filterUnusedData } from "views/admin/form/formHandlers";

const initialFormData = {
  property_type: "",
  ad_type: "",
  comment: "",
  location: {},
};

const UserRequirementModal = ({ isOpen, onClose, userId, selectedRequirement, afterRequirementsUpdate }) => {
  const [requirements, setRequirements] = useState([]);
  const [formData, setFormData] = useState(initialFormData);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const toast = useToast();
  const { apiCall } = useApi();
  console.log('form data', formData);

  const fetchRequirements = async () => {
    try {
      const url = `${USER_REQUIREMENTS_API}?user_id=${userId}`;
      const response = await apiCall.get(url);
      setRequirements(response.data.requirements);
    } catch (error) {
      console.error("Error fetching requirements:", error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchRequirements();
    }
  }, [isOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
        setIsSubmittingForm(true);
        const payload = {
            ...formData,
            ...(formData._id ? { requirment_id: formData?._id } : { user_id: userId })
        };
        filterUnusedData(payload)
      const response = await submitRequirement({apiCall, payload});
      if(response.data){
        setIsSubmittingForm(false);
        handleReset();
        await fetchRequirements();
        if(afterRequirementsUpdate){
          afterRequirementsUpdate(response.data);
        }
      }
    } catch (error) {
      console.error("Error submitting requirement:", error);
      setIsSubmittingForm(false);
      showToast({
        message: error,
        success: false,
        customStyle: {
          background: "#F24052",
          color: "#fff",
          padding: "10px",
        },
      });
    }
  };

  const handleReset = () => {
    setFormData(initialFormData);
  };

  const handleEdit = (requirement) => {
    setFormData(requirement);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/user-requirements/${id}`
      );
      fetchRequirements();
      toast({
        title: "Requirement deleted successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting requirement:", error);
    }
  };

  const handleLocation = (e) => {
    const { value: selectedValue } = e.target;
    const updatedValue = {
      city: selectedValue.name,
    };
    setFormData((prev) => ({
      ...prev,
      ["location"]: updatedValue,
    }));
  };

  const handleLocality = (e) => {
    const { name, value: selectedValue } = e.target;
    if (name === "locality") {
      const data = JSON.parse(selectedValue);
      setFormData((prev) => ({
        ...prev,
        ["location"]: data,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        ["location"]: {
          ...formData.location,
          [name]: selectedValue,
        },
      }));
    }
  };
  useEffect(() => {
    if (selectedRequirement && Object.keys(selectedRequirement).length > 0) {
      handleEdit(selectedRequirement);
    }
  }, [selectedRequirement]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Flex direction={{ base: "column", md: "column" }} gap={2}>
            {/* Form Section */}
            <UserRequirementForm
              formData={formData}
              handleChange={handleChange}
              handleLocation={handleLocation}
              handleLocality={handleLocality}
              handleSubmit={handleSubmit}
              handleReset={handleReset}
              editingId={formData._id}
              isDisabled={isSubmittingForm}
            />

            {/* List Section */}
            {requirements?.length > 0 && (
              <UserRequirementList
                requirements={requirements}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
              />
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UserRequirementModal;
