import React from "react";
import { Box, Flex, Heading, List, ListItem, IconButton } from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { displayRequirement } from "../../services/userRequirementsService";

const UserRequirementList = ({ requirements, handleEdit, handleDelete }) => {

  return (
    <Box flex={1}>
      <Heading size="md" mb={4}>
        Existing Requirements
      </Heading>
      <List spacing={3} overflowY="auto" maxH="428px">
        {requirements.map((req) => (
          <ListItem key={req._id} borderBottom="1px solid #e2e8f0" pb={2}>
            <Flex justifyContent="space-between">
              <Box>{displayRequirement(req)}</Box>
              <Box>
                <IconButton
                  icon={<EditIcon />}
                  aria-label="Edit"
                  size="xs"
                  colorScheme="teal"
                  onClick={() => handleEdit(req)}
                  mr={2}
                  fontSize="xs"
                  mb={2} 
                />
              </Box>
            </Flex>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default UserRequirementList;
