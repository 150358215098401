const { COMPANY_EXPERTISE_IN_TAGS } = require("constants/dbEnums");
const Joi = require("joi");

const companySchema = {
  create: Joi.object({
    user_id: Joi.string().regex(/^[0-9a-fA-F]{24}$/).required().messages({
       "string.base": "user_id must be a string",
       "string.empty": "user_id cannot be empty",
     }),
     photo:  Joi.string().allow(''),
     name: Joi.string().trim().required(),
     registered_name: Joi.string().trim().required(),
     cin: Joi.string(),
     registration_date: Joi.date().required(),
     about: Joi.string().trim().max(300).required(),
     address: Joi.object({
      type: Joi.string(),
      label: Joi.string(),
      place_type: Joi.string().allow(""),
      coordinates: Joi.array().items(Joi.number()).required(),
      city: Joi.string().required().trim(),
      state: Joi.string(),
      state_code: Joi.string().allow(""),
      country_code: Joi.string().allow(""),
      country: Joi.string(),
      locality: Joi.string().required(),
      sub_locality: Joi.string().allow(""),
      street: Joi.string().allow("").trim(),
      building: Joi.string().allow(""),
      postal_code: Joi.string().allow(""),
    })
       .required()
       .custom((value, helpers) => {
         if (!value.locality || value.locality.trim() === "") {
           return helpers.error("any.custom", { key: "locality" });
         }
         return value;
       }),
     gst_no: Joi.string().trim().uppercase(),
     RERA_ID: Joi.string().trim(),
     rera_certificate: Joi.string().trim(),
     gst_certificate: Joi.string().trim(),
     total_members: Joi.string().allow(''),
     expertise_in: Joi.array().items(Joi.string().valid(...Object.values(COMPANY_EXPERTISE_IN_TAGS))),
     website: Joi.string(),
     email: Joi.string(),
     trade_license_registration: Joi.boolean().default(false),
 }),
 update: Joi.object({
  _id: Joi.string().regex(/^[0-9a-fA-F]{24}$/).required().messages({
    "string.base": "_id must be a string",
    "string.empty": "_id cannot be empty",
  }),
  user_id: Joi.string().regex(/^[0-9a-fA-F]{24}$/).required().messages({
     "string.base": "user_id must be a string",
     "string.empty": "user_id cannot be empty",
   }),
   photo:  Joi.string().allow(''),
   name: Joi.string().trim().required(),
   registered_name: Joi.string().trim().required(),
   cin: Joi.string(),
   registration_date: Joi.date().required(),
   about: Joi.string().trim().max(300).required(),
   address: Joi.object({
     type: Joi.string(),
     label: Joi.string(),
     place_type: Joi.string().allow(''),
     coordinates: Joi.array().items(Joi.number()).required(),
     city: Joi.string().required().trim(),
     state: Joi.string(),
     state_code: Joi.string().allow(''),
     country_code: Joi.string().allow(''),
     country: Joi.string(),
     locality: Joi.string().required(),
     sub_locality: Joi.string(),
     street: Joi.string().allow("").trim(),
     building: Joi.string(),
     postal_code: Joi.string(),
   })
     .required()
     .custom((value, helpers) => {
       if (!value.locality || value.locality.trim() === "") {
         return helpers.error("any.custom", { key: "locality" });
       }
       return value;
     }),
   gst_no: Joi.string().trim().uppercase(),
   RERA_ID: Joi.string().trim(),
   rera_certificate: Joi.string().trim(),
   gst_certificate: Joi.string().trim(),
   total_members: Joi.string().allow(''),
   expertise_in: Joi.array().items(Joi.string().valid(...Object.values(COMPANY_EXPERTISE_IN_TAGS))),
   website: Joi.string(),
   email: Joi.string(),
   trade_license_registration: Joi.boolean().default(false),
}).unknown()
}

export const companyKeysArrya = ["address_locality"];
export const companyKeys = {
  "address": "address_locality",
  "locality": "address_locality"
}

export default companySchema;