import { PROPERTY_STATUS } from 'constants/dbEnums';

import { createIcon } from '@chakra-ui/icons';
import { formatDate } from 'constants/commonFunctions';
import { TRANSACTION_TYPE } from 'constants/dbEnums';
import { AD_TYPE } from 'constants/dbEnums';
import { PROPERTY_TYPE } from 'constants/dbEnums';
import { formatLocation } from 'constants/commonFunctions';
import { JOB_APPLICATION_STATUS } from 'constants/dbEnums';
import { formatTime } from 'constants/commonFunctions';
import randomstring from 'randomstring';
import { PROPERTY_TYPE_META_TAGS } from 'constants/dbEnums';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { USER_ROLE } from 'constants/dbEnums';
import { STATIC_CONTENT_DATA_API } from 'constants/endpoints';
import axios from 'axios';
import { RESIDENTIAL_PROPERTY_ARRAY } from 'constants/dbEnums';
import { COMMERCIAL_PROPERTY_ARRAY } from 'constants/dbEnums';

export const formatAdsTableData = (data) => {
  return data.map((item) => ({
    owner_email: item.owner.email || '-',
    owner_phone: item.owner.phone || '-',
    collection_type: TRANSACTION_TYPE[item.collection_type] || '-',
    ad_type: AD_TYPE[item.ad_type] || '-',
    property_type: PROPERTY_TYPE[item.property_type] || '-',
    created_at: `${formatDate(item.created_at)}, ${formatTime(item.created_at)}` || '-',
    slug_url: item.slug_url || '-',
    verified: item.verified,
    property_status: PROPERTY_STATUS[item.property_status] || '-',
    expires_on: formatDate(item.expires_on) || '-',
    location: formatLocation(item.location) || '-',
    meta_title: item.meta_title || '-',
    meta_description: item?.meta_description || '-',
    listing_score: item.total_listing_score || 0,
    _id: item._id || '',
    sid: item.sid || '-',
    stat_slug_url: item.slug_url || '-',
    user_id:item?.owner?._id || ''
  }));
};

export const formatJobApplicationData = (data) => {
  return data.map((item) => ({
    email: item.email || '-',
    phone: item.phone || '-',
    status: JOB_APPLICATION_STATUS[item.status] || '-',
    resume: item.resume || '-',
    job_id: item.job_id || '-',
    job_title: item.job_title || '-',
    answer: item.answer || '',
    comment: item.comment || '-',
    skills: item.skills || '-',
    education: item.education || '-',
    passout_year: item.passout_year || '-',
    current_location: item.current_location || '-',
    work_type_preference: item.work_type_preference || '-',
    experience: item.experience || '-',
    name: [`${item.first_name} ${item.last_name}`, false],
    created_at: `${formatDate(item.created_at)}, ${formatTime(item.created_at)}` || '-',
    last_reminder_email_sent_on: item.last_reminder_email_sent_on ? `${formatDate(item.last_reminder_email_sent_on)}, ${formatTime(item.last_reminder_email_sent_on)}` : '-',
    _id: item._id,
    dev_asignee: item.dev_asignee ?  `${item.dev_asignee.first_name} ${item.dev_asignee.last_name}` : '-',
    deadline: item.deadline ? `${formatDate(item.deadline)}, ${formatTime(item.deadline)}` : '-',
    last_comment_updated_on: item.last_comment_updated_on ? `${formatDate(item.last_comment_updated_on)}, ${formatTime(item.last_comment_updated_on)}` : '-'
  }));
};

export const formatUsersTableData = (data) => {
  return data?.map((user) => {
    return {
      _id: user._id,
      name: `${user.first_name} ${user.last_name}`,
      email: user.email ? user.email : '-',
      phone: user.phone,
      role: user?.role,
      verified: user.verified ? 'Yes' : 'No',
      phone_verified: user.phone_verified ? 'Yes' : 'No',
      email_verified: user.email_verified ? 'Yes' : 'No',
      onboarding_completed: user.onboarding_completed ? 'Yes' : 'No',
      platform: user?.platform
        ? Object?.keys(user.platform)
            .filter((key) => user.platform[key])
            .map((key) => key.charAt(0).toUpperCase() + key.slice(1))
            .join(', ') || '-'
        : '-',
      plan: user.plan,
      active_properties: user.total_properties,
      created_at: `${formatDate(user.created_at)}, ${formatTime(user.created_at)}`,
      status: user?.phone_verified ? 'Verified' : 'Unverified',
      add_requirement: 'Add Requirement',
      actions: '-',
    };
  });
};

export const formatBuildersTableData = (data) => {
  return data?.map((builder) => {
    return {
      _id: builder._id,
      name: [
        `${builder?.user?.first_name}  ${builder?.user?.last_name}`,
        false,
      ],
      status: PROPERTY_STATUS[builder.status] || '-',
      experience: builder.experience,
      company_name: builder.company.name,
      active_properties: builder?.total_properties,
      slug_url: builder?.user?.slug_url || '-',
      total_projects: builder?.total_projects,
    };
  });
};

export const formatAgentsTableData = (data) => {
  return data?.map((agent) => {
    return {
      _id: agent._id,
      name: [`${agent?.user?.first_name}  ${agent?.user?.last_name}`, false],
      email: agent?.company?.email || '-',
      status: PROPERTY_STATUS[agent.status] || '-',
      slug_url: agent?.user?.slug_url || '-',
      company_name: agent?.company?.name || '-',
    };
  });
};

export const formatProjectsTableData = (data) => {
  return data.map((item) => ({
    slug_url: item.slug_url,
    name: item.name,
    uid: item.uid,
    company_name: item?.builder_details?.company_details?.name,
    property_type: item?.property_type,
    project_status: item?.project_status,
    status: PROPERTY_STATUS[item?.status] || '-',
    address: `${item?.address?.locality},${item?.address?.city},${item?.address?.state}`,
  }));
};

export const isValidPhoneNumber = (phoneNumber) => {
  const phoneRegex = /^\d{10}$/;

  return phoneRegex.test(phoneNumber);
};

export const formatUserRequirementsTableData = (data) => {
  return data.map((item) => ({
    _id: item._id,
    user_id: item.user_id,
    property_type: item.property_type,
    ad_type: item.ad_type,
    comment: item.comment,
    address: `${item?.location?.locality}, ${item?.location?.city}, ${item?.location?.state}`,
    full_name: item.user_data?.full_name,
    phone: item.user_data?.phone,
    role: item.user_data?.role,
    email: item.user_data?.email,
    status: 'Add'
  }));
};

export const formatBrokerGroupTableData = (data) => {
  return data.map((item) => ({
    _id: item._id,
    name: item.name,
    property_type: item.property_type,
    ad_type: item.ad_type,
    comment: item.comment,
    address: `${item?.location?.locality}, ${item?.location?.city}, ${item?.location?.state}`,
    status: 'Add'
  }));
};

export const userColumnsData = {
  NAME: 'NAME',
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
  ROLE: 'ROLE',
  VERIFIED: 'VERIFIED',
  ONBOARDING: 'ONBOARDING',
  PLATFORM: 'PLATFORM',
  PLAN: 'PLAN',
  ACTIVE_PROPERTIES: 'ACTIVE_PROPERTIES',
  CREATED_AT: 'CREATED_AT',
  ACTIONS: 'ACTIONS',
};

export const propertiesColumnsData = {
  SLUG: 'SLUG',
  SID: 'SID',
  OWNER_EMAIL: 'OWNER EMAIL',

  OWNER: 'OWNER',

  TRANSACTION_TYPE: 'TRANSACTION TYPE',

  AD_TYPE: 'AD TYPE',

  PROPERTY_TYPE: 'PROPERTY TYPE',

  POSTED_ON: 'POSTED ON',

  PROPERTY_VERIFIED: 'PROPERTY VERIFIED',

  PROPERTY_STATUS: 'PROPERTY STATUS',

  EXPIRES_ON: 'EXPIRES ON',

  LOCATION: 'LOCATION',

  ACTIONS: 'ACTIONS',

  META_TITLE: 'META TITLE',

  META_DESCRIPTION: 'META DESCRIPTION',

  LISTING_SCORE: 'LISTING SCORE',
};

export const buildersColumnsData = {
  NAME: 'NAME',
  STATUS: 'STATUS',
  VERIFIED: 'VERIFIED',
  EXPERIENCE: 'EXPERIENCE',
  COMPANY_NAME: 'COMPANY NAME',
  ACTIONS: 'ACTIONS',
  SLUG: 'SLUG',
};

export const getFilterFromLocalStorage = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

export const setFilterToLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const ENTITY_FILTER_TYPES = {
  USERS_FILTER: 'userFilters',
  BUILDERS_FILTER: 'builderFilters',
  ADS_FILTER: 'adsFilters',
  AGENTS_FILTER: 'agentFilters',
  ADS_PAGE_SIZE: 'adsPageSize',
  USERS_PAGE_SIZE: 'usersPageSize',
  BUILDERS_PAGE_SIZE: 'buildersPageSize',
  AGENTS_PAGE_SIZE: 'agentsPageSize',
  ADS_DATE_RANGE: 'adsDateRange',
  USERS_DATE_RANGE: 'usersDateRange',
  BUILDERS_DATE_RANGE: 'buildersDateRange',
  AGENTS_DATE_RANGE: 'agentsDateRange',
  DASHBOARD_ADS_DATE_RANGE: 'dashboardAdsDateRange ',
  PROJECTS_FILTER: 'projectsFilter',
  PROJECTS_DATE_RANGE: 'projectsDateRange',
  PROJECTS_PAGE_SIZE: 'projectsPageSize',
  USER_REQUIREMENTS_FILTER: 'userRequirementsFilter',
  USER_REQUIREMENTS_PAGE_SIZE: 'userRequirementsPageSize',
  USER_REQUIREMENTS_DATE_RANGE: 'userRequirementsDateRange',
};

export const ENTITY_COLUMNS_TYPES = {
  USERS_COLUMNS: 'usersColumns',
  BUILDERS_COLUMNS: 'buildersColumns',
  ADS_COLUMNS: 'adsColumns',
  AGENTS_COLUMNS: 'agentsColumns',
  PROJECTS_COLUMNS: 'projectsColumns',
  USER_REQUIREMENTS_COLUMNS: 'userRequirementsColumns'
};

export const CustomIcon = createIcon({
  displayName: 'CustomIcon',
  viewBox: '0 0 24 24',
  path: (
    <g
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
      <line x1="9" y1="3" x2="9" y2="21" />
      <line x1="15" y1="3" x2="15" y2="21" />
    </g>
  ),
});

export const getOneMonthFromToday = () => {
  const nextMonth = new Date();
  nextMonth.setMonth(nextMonth.getMonth() + 1);
  return nextMonth.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
};

export function areAllStatusesSame(statusArray) {
  return statusArray?.every((status) => status === statusArray[0]);
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export const formatDateToDDMMYYYY = (inputDate) => {
  if (!inputDate) return '';

  let date;

  if (typeof inputDate === 'string' || typeof inputDate === 'number') {
    date = new Date(inputDate);
  } else if (inputDate instanceof Date) {
    date = inputDate;
  } else {
    return 'Invalid date';
  }

  // Check if date is valid
  if (isNaN(date.getTime())) {
    return 'Invalid date';
  }

  // Format the date
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

export const formatDateToDDMONTHNAMEYYYY = (inputDate) => {
  if (!inputDate) return 'Not selected';

  let date;

  // Handle different types of input
  if (typeof inputDate === 'string' || typeof inputDate === 'number') {
    date = new Date(inputDate);
  } else if (inputDate instanceof Date) {
    date = inputDate;
  } else {
    return 'Invalid date';
  }

  // Check if date is valid
  if (isNaN(date.getTime())) {
    return 'Invalid date';
  }

  const day = String(date?.getDate())?.padStart(2, '0');
  const month = date.toLocaleString('default', { month: 'short' });
  const year = String(date.getFullYear()).slice(2);

  return `${day}-${month}-${year}`;
};

export const getStatusColor = (status) => {
  switch (status) {
    case 'Pending':
      return { colorScheme: 'orange' };
    case 'Expired':
    case 'Rejected':
      return { colorScheme: 'red' };
    case 'Deleted':
    case 'Inactive':
      return { colorScheme: 'gray' };
    case 'Active':
    case 'Shortlisted':
      return { colorScheme: 'green' };
    case 'Owner':
    case 'Reviewed':
      return { colorScheme: 'blue' };
    case 'Agent':
      return { colorScheme: 'purple' };
    case 'Builder':
      return { colorScheme: 'teal' };
    case 'Admin':
      return { colorScheme: 'pink' };
    case 'Freemium':
      return { colorScheme: 'yellow' };
    case 'Premium':
      return { colorScheme: 'cyan' };
    case 'Hexa Premium':
      return { customColor: 'purple' }; // Custom color
    case 'Need To Complete Assignment':
      return { colorScheme: 'yellow' }; // Custom color
    case 'Assignment Submitted':
      return { colorScheme: 'cyan' }; // Custom color
    case 'Assignment Submitted':
      return { colorScheme: 'purple' }; // Custom color
    default:
      return { colorScheme: 'gray' };
  }
};

export function createHandleRowSelect(key) {
  return (row, setSelectedRowsData, selectedRowsData) => {
    const findData = selectedRowsData.find((item) => item[key] === row[key]);

    setSelectedRowsData((prev) => {
      if (findData) {
        return prev.filter((item) => item[key] !== findData[key]);
      } else {
        return [...prev, row];
      }
    });
  };
}

const excluded = ['Rent', 'Sell'];
export const transformedTotalAdsDataFn = (dashboardData) => {
  // Transform nested data
  const nestedData = Object.entries(dashboardData)
    .map(([key, value]) => {
      if (typeof value === 'object') {
        return {
          type: key.charAt(0).toUpperCase() + key.slice(1),
          total: value.total,
          active: value.active,
          pending: value.pending,
          expired: value.expired,
          deleted: value.deleted,
          rejected: value.rejected,
          inactive: value.inactive,
        };
      }
      return null;
    })
    .filter(Boolean)
    .filter((item) => (excluded.includes(item.type) ? false : true));

  return [...nestedData];
};

export const transformedActiveAdsDataFn = (response) => {
  const { rent = {}, sell = {} } = response;

  const allKeys = new Set([...Object.keys(rent), ...Object.keys(sell)]);
  allKeys.delete('total');

  return Array.from(allKeys).map((type) => ({
    type: PROPERTY_TYPE[type] || type,
    rent: rent[type] || 0,
    sell: sell[type] || 0,
    total_ads: (rent[type] || 0) + (sell[type] || 0),
  }));
};

export const transformUserData = (data) => {
  return [
    {
      user_type: 'Agents',
      active: data?.agents?.active,
      inactive: data?.agents?.inactive,
      pending_verification: data?.agents?.pending_verification,
      rejected: data?.agents?.rejected,
    },
    {
      user_type: 'Builders',
      active: data?.builders?.active,
      inactive: data?.builders?.inactive,
      pending_verification: data?.builders?.pending_verification,
      rejected: data?.builders?.rejected,
    },
    {
      user_type: 'Projects',
      active: data?.projects?.active,
      inactive: data?.projects?.inactive,
      pending_verification: data?.projects?.pending_verification,
      rejected: data?.projects?.rejected,
    },
  ];
};

export const formatDateToMonth = (dateString) => {
  const date = new Date(dateString);
  const options = { year: 'numeric', month: 'short', day: 'numeric' };

  return date.toLocaleDateString('en-US', options).replace(/,/, '');
};

export const getScoreCardDetails = (score) => {
  let listingScoreColor = { track: '#808080', filled: '#404040' };
  if (score >= 90)
    listingScoreColor = { track: '#178C0C33', filled: '#178C0C' };
  else if (score >= 65)
    listingScoreColor = { track: '#FFEAD5', filled: '#F57C00' };
  else listingScoreColor = { track: '#C7161C33', filled: '#C7161C' };

  let listingScoreText = {
    text: 'Listing Score',
    subText:
      'Increase listing score by adding more details about this property.',
  };
  if (score >= 90)
    listingScoreText = {
      text: 'Your Listing score is Excellent',
      subText:
        'Congrats on a good score! Keep it up with your upcoming listing.',
    };
  else if (score >= 65)
    listingScoreText = {
      text: 'Your Listing score is Average',
      subText:
        'Enhance your listing with more details and Images for better results.',
    };
  else
    listingScoreText = {
      text: 'Your Listing score is Low',
      subText:
        'Improve your listing score! Add more details and photos for better visibility.',
    };

  return { listingScoreColor, listingScoreText };
};


export const LEAD_ACTION={
  bookmark:'Bookmark',
  page_view:'Viewed',
  view_number:"View Number",
  call:"Call",
  requested_callback:"Requested Callback",
  requested_photo:"Requested Photo"
}


export const FILTER_LEAD_ACTION={
  bookmark:'bookmark',
  page_view:'page_view',
  view_number:"view_number",
  call:"call",
  requested_callback:"requested_callback",
  requested_photo:"requested_photo"
}

export const shortRandomIdGenerator = (length = 6)=>{
  const sid = randomstring.generate({
      length,
      charset: 'alphanumeric',
      capitalization: 'uppercase',
      readable: true
  });
  return sid;
}

export const createMetaTitle = (name, address, propertyType = null) => {
  const MAX_LENGTH = 60;
  const propertyTypesTags = propertyType
    .map((type) => PROPERTY_TYPE_META_TAGS[type] || "")
    .filter((tag) => tag)
    .join(" ");
  let metaTitle = `${name}${address.city ? " " + address.city : ""}`;
  if (metaTitle.length < MAX_LENGTH && address?.locality) {
    metaTitle = `${name} ${address.locality} ${address.city || ""}`;
  }
  if (metaTitle.length < MAX_LENGTH && propertyType) {
    metaTitle = `${metaTitle} | ${propertyTypesTags}`;
  }
  return metaTitle;
};

export const createMetaDescription = (formData) => {
  const { name = "", property_type = [], address = {}, brochure = false, floorPlanAndPricing = [], amenities = [] } = formData;

  const propertyTypesTags = property_type
  .map((type) => PROPERTY_TYPE_META_TAGS[type] || "")
  .filter((tag) => tag)
  .join(" ");
  let metaDescription = '';
  
  metaDescription += `${name} ${propertyTypesTags}`;
  if (address && address.locality) {
    metaDescription += ` in ${address.locality}`;
  }
  if (brochure) {
    metaDescription += ' – Download brochures, ';
  }
  const hasRentPrice = floorPlanAndPricing.some(item => item.rentPrice);
  const hasSellingPrice = floorPlanAndPricing.some(item => item.sellingPrice);

  if (hasRentPrice || hasSellingPrice) {
    metaDescription += 'view prices, ';
  }

  if (hasRentPrice || hasSellingPrice) {
    metaDescription += 'explore floor plans on our site. ';
  }

  if (amenities.length > 0) {
    metaDescription += `With top amenities}.`;
  }

  metaDescription = metaDescription.replace(/,\s*$/, '');

  if (metaDescription.length > 160) {
    metaDescription = metaDescription.substring(0, 157) + '...';
  }
  return metaDescription;
};

export function renameSlugUrl(slugUrl, newName) {
  const formattedName = newName.toLowerCase().replace(/\s+/g, '-');
  let parts;

  if (slugUrl?.includes('-buid-')) {
    parts = slugUrl?.split('-buid-');
  } else if (slugUrl?.includes('-agentid-')) {
    parts = slugUrl?.split('-agentid-');
  } else {
    throw new Error('Invalid slug format');
  }
  
  if (parts?.length !== 2) {
      throw new Error('Invalid slug format');
  }
  const identifier = slugUrl.includes('-buid-') ? '-buid-' : '-agentid-';
  const newSlug = `${formattedName}${identifier}${parts[1]}`;
  return newSlug;
}

export function useQueryParams() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  
  return {
    propertyId: queryParams.get('id') || null,
    collectionType: queryParams.get('type') || null,
    propertyTypeFromQuery: queryParams.get('property_type') || null,
    ownerId: queryParams.get('owner_id') || null,
    companyName: queryParams.get('company_name') || null,
    builderId: queryParams.get('builder_id') || null,
    slugUrl: queryParams.get('slug_url') || null,
    agentId: queryParams.get('agent_id') || null,
  };
}
export const roleBadgeColor = (role) => {
  switch (role) {
      case USER_ROLE.ADMIN:
      case USER_ROLE.MARKETING_ADMIN:
      case USER_ROLE.HR_ADMIN:
          return "red"
      case USER_ROLE.OWNER:
          return "green"
      case USER_ROLE.AGENT_DEALER:
          return "purple"
      case USER_ROLE.BUILDER:
          return "teal"
      default:
          return "gray"
  }
}

export const truncateString = (str, num = 20) => {
  if (str?.length > num) return str.slice(0, num) + "...";
  return str || "-";
}

export const createValueLabelArray = (key) => {
  return [
    { value: "true", label: `${key}` }
  ];
};

export const getDataFromLocalStorage = (key) => {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : null;
};

export const getTopCities =async () => {
  const storedCities = getDataFromLocalStorage("topCities");
  if (storedCities) {
    return storedCities;
  } else {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}${STATIC_CONTENT_DATA_API("/cities.json")}`;
      const response = await axios.get(url);
      const data = response?.data.data;
      localStorage.setItem("topCities", JSON.stringify(data));
      return data;
    } catch (error) {
      console.error("Error fetching cities:", error);
      throw error;
    }
  }
};

export const getTagsForSelectedInput = (selectedTypes) => {
  let tags = {};

  if (selectedTypes?.includes("residential_property")) {
    RESIDENTIAL_PROPERTY_ARRAY.forEach(tag => {
      tags[tag.id] = tag.name;
    });
  }

  if (selectedTypes?.includes("commercial_property")) {
    COMMERCIAL_PROPERTY_ARRAY.forEach(tag => {
      tags[tag.id] = tag.name;
    });
  }

  return tags;
};
